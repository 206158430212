import {Link} from 'react-router-dom';
import {CustomMenu} from '../../../menus/custom-menu';
import {useSettings} from '../../../core/settings/use-settings';
import {Trans} from '../../../i18n/trans';

export function AuthLayoutFooter() {
  const {branding} = useSettings();
  const year = new Date().getFullYear();
  return (
    <div className="pt-42 pb-32 flex items-center gap-30 text-sm text-muted mt-auto">
      <Trans
        message="Copyright © :year, All Rights Reserved"
        values={{year, name: branding.site_name}}
      />
      <CustomMenu
        menu="auth-page-footer"
        orientation="horizontal"
        itemClassName="hover:text-fg-base transition-colors"
      />
    </div>
  );
}
