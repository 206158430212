import {Trans} from '../../i18n/trans';
import {Product} from '../product';
import {CheckIcon} from '@common/icons/material/Check';

interface FeatureListProps {
  product: Product;
}

export function ProductFeatureList({product}: FeatureListProps) {
  if (!product.feature_list.length) return null;

  return (
    <div className=" border-t ">
      
                              {product.feature_list.map(feature => (
                              <li key={feature}>
                                <p><span><Trans message={feature} /></span></p>
                              </li>
                              ))}  
      
      
    </div>
  );
}
