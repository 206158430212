import {useProducts} from './use-products';
import {Button} from '../../ui/buttons/button';
import {Trans} from '../../i18n/trans';
import {ForumIcon} from '../../icons/material/Forum';
import {Navbar} from '../../ui/navigation/navbar/navbar';
import {Link} from 'react-router-dom';
import {Footer} from '../../ui/footer/footer';
import {Fragment, useState} from 'react';
import {UpsellBillingCycle} from './find-best-price';
import {BillingCycleRadio} from './billing-cycle-radio';
import {StaticPageTitle} from '../../seo/static-page-title';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';

export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('monthly');

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
      />
      <div className="container-fulid">
        <div className="">
          <div id="generic_price_table"  className="pb-60">
            <section className="section-1">
                 
                    <div className="price-heading clearfix pt-10">
                      <h1>POCKET FRIENDLY <span className="month_text"> MONTHLY PLANS</span> <br />WITH
                        CONCIERGE SUPPORT
                      </h1>
                    </div>
              
              
                <section className='content w-full new100'  >
                  <div className="row-fluid clearfix">
                    <BillingCycleRadio
                      products={query.data?.products}
                      selectedCycle={selectedCycle}
                      onChange={setSelectedCycle}
                      className="invisible justify-center "
                      size="lg"
                    />
                    <div className="pricing-wrapper comparison-table clearfix style-3 flex justify-center">                      
                      <PricingTable
                        selectedCycle={selectedCycle}
                        productLoader="pricingPage"
                      />
                    </div>
                  </div>
                </section>      
              
            </section>
          </div>
        </div>
        <section>
          <div className="website-features-wrapper mt-50 mb-50">
            <div className="col-12">
              <div className="text-center">
                <h2 className="font-0">WEBSITE FEATURES</h2>
              </div>
            </div>
          </div>
          <div className="website-features">
            <div className="flex gap-20 justify-center mt-4 mb-30 mr-100 ml-100">
              <div className="col-md-4">
                <div className="card card-responsive-0 card-000">
                  <div className="card-body">
                    <span className="img-0">
                    <i className="ri-map-pin-line"></i>
                    </span>
                    <h5 className="card-title mt-3">Ideal for Local Business</h5>
                    <p className="card-text">Designed to help your business
                      rank in local search results
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-responsive-0 res-0">
                  <div className="card-body">
                    <span className="img-0">
                    <i className="ri-map-pin-fill"></i>
                    </span>
                    <h5 className="card-title  mt-3">Google Maps Integration</h5>
                    <p className="card-text">Easily guide customers to your
                      location with built-in Google Maps.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-responsive-0 res-1">
                  <div className="card-body">
                    <span className="img-0"><i className="ri-seo-line"></i>
                    </span>
                    <h5 className="card-title mt-3">SEO-Ready Structure
                    </h5>
                    <p className="card-text">Your website comes optimized for
                      search engines.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-20 justify-center mt-4 mb-30 mr-100 ml-100">
              <div className="col-md-4">
                <div className="card card-responsive-0 res-2">
                  <div className="card-body">
                    <span className="img-0">
                    <i className="ri-service-line"></i>
                    </span>
                    <h5 className="card-title mt-3">Concierge Service
                    </h5>
                    <p className="card-text">Receive premium support with our
                      dedicated concierge service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-responsive-0 res-3">
                  <div className="card-body">
                    <span className="img-0">
                    <i className="ri-pages-line"></i>
                    </span>
                    <h5 className="card-title  mt-3">Feature Rich Website
                    </h5>
                    <p className="card-text">Get up to 10-page websites with
                      multiple contact forms and popups
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-responsive-0 res-4">
                  <div className="card-body">
                    <span className="img-0"><i className="ri-layout-line"></i>
                    </span>
                    <h5 className="card-title mt-3">Responsive Design
                    </h5>
                    <p className="card-text">Your website will look great on any device,
                      ensuring a smooth user experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="website-features-wrapper mt-50 mb-50">
          <div className="row">
            <div className="text-center">
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="centerplease"></div>
              <br />
              <div className="faq content pa-0">
                <div>
                  <input type="checkbox" id="question1" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question1" className="question">
                   What are the key features of your website builder plans?
                  </label>
                  <div className="answers">
                    Our website builder plans are designed to help local businesses thrive online. Key features include:
                    <ul>
                      <li>-SEO-ready structure to enhance search engine visibility.</li>
                      <li>-Google Maps integration for easy customer navigation.</li>
                      <li>-A feature-rich website with contact forms and popups.</li>
                      <li>-Responsive design for optimal viewing on any device.</li>
                      <li>-Concierge service for premium support.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question2" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question2" className="question">
                  How many pages can I have on my website?
                  </label>
                  <div className="answers">
                    We offer plans with a 5-page or 10-page website limit, depending on your chosen subscription. Both options are crafted to suit your business needs.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question3" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question3" className="question">
                  Can I get business email addresses with my subscription?
                  </label>
                  <div className="answers">
                    Yes! Depending on your plan, you can receive 1 or up to 3 business email addresses to enhance your professional communication.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question4" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question4" className="question">
                  Do you offer website editing support?
                  </label>
                  <div className="answers">
                    Absolutely! We provide quarterly editing hours to help you make updates. You can use 2 or 5 hours per quarter, depending on your subscription.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question5" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question5" className="question">
                  Will my website work on mobile devices?
                  </label>
                  <div className="answers">
                    Yes, your website will be fully responsive, meaning it will look great and function seamlessly across all devices, from desktops to smartphones.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question6" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question6" className="question">
                   Is my website optimized for search engines?
                  </label>
                  <div className="answers">
                    Yes, every website we build comes with an SEO-ready structure to help improve your visibility on search engines like Google.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question7" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question7" className="question">
                  How does the Google Maps integration work?
                  </label>
                  <div className="answers">
                   Google Maps is seamlessly integrated into your website, making it easy for customers to locate your business with a click.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question8" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question8" className="question">
                  What is the concierge service?
                  </label>
                  <div className="answers">
                    Our concierge service provides you with dedicated premium support, ensuring your website setup and maintenance is stress-free.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question9" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question9" className="question">
                  Is this service suitable for local businesses?
                  </label>
                  <div className="answers">
                    Yes, our plans are specifically designed to help local businesses rank better in local search results and connect with nearby customers.
                  </div>
                </div>
                <div>
                  <input type="checkbox" id="question10" name="q" className="questions" />
                  <div className="plus">+</div>
                  <label htmlFor="question10" className="question">
                  Can I upgrade my plan later?
                  </label>
                  <div className="answers">
                    Of course! You can upgrade your plan anytime to unlock more features and benefits as your business grows.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <Footer className=" " />
    </Fragment>
  );
}

function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="my-8 md:text-lg">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}
