import {AnimatePresence, m} from 'framer-motion';
import {Fragment} from 'react';
import {opacityAnimation} from '@common/ui/animation/opacity-animation';
import {Skeleton} from '@common/ui/skeleton/skeleton';
import {useProducts} from '@common/billing/pricing-table/use-products';
import {Product} from '@common/billing/product';
import {
  findBestPrice,
  UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';
import {useAuth} from '@common/auth/use-auth';
import clsx from 'clsx';
import {Chip} from '@common/ui/forms/input-field/chip-field/chip';
import {Trans} from '@common/i18n/trans';
import {FormattedPrice} from '@common/i18n/formatted-price';
import {Button} from '@common/ui/buttons/button';
import {Link} from 'react-router-dom';
import {setInLocalStorage} from '@common/utils/hooks/local-storage';
import {ProductFeatureList} from '@common/billing/pricing-table/product-feature-list';
import {FormattedCurrency} from '@common/i18n/formatted-currency';

interface PricingTableProps {
  selectedCycle: UpsellBillingCycle;
  className?: string;
  productLoader?: string;
}
export function PricingTable({
  selectedCycle,
  className,
  productLoader,
}: PricingTableProps) {
  const query = useProducts(productLoader);
  return (
    
      <AnimatePresence initial={false} mode="wait">
        {query.data ? (
          <PlanList
            key="plan-list"
            plans={query.data.products}
            selectedPeriod={selectedCycle}
          />
        ) : (
          <SkeletonLoader key="skeleton-loader" />
        )}
      </AnimatePresence>
    
  );
}

interface PlanListProps {
  plans: Product[];
  selectedPeriod: UpsellBillingCycle;
}
function PlanList({plans, selectedPeriod}: PlanListProps) {
  const {isLoggedIn, isSubscribed} = useAuth();
  const filteredPlans = plans.filter(plan => !plan.hidden);
  return (
    <Fragment>
      {filteredPlans.map((plan, index) => {
        const isFirst = index === 0;
        const isLast = index === filteredPlans.length - 1;
        const price = findBestPrice(selectedPeriod, plan.prices);

        let upgradeRoute;
        if (!isLoggedIn) {
          upgradeRoute = `/register?redirectFrom=pricing`;
        }
        if (isSubscribed) {
          upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
        }
        if (isLoggedIn && !plan.free) {
          upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
        }

        return (
          
            <m.div className="pricing-col person" key={plan.id}>
           
                <div className="pricing-card">
                  <div className="pricing-header">
                    <h5>{plan.name}</h5>
                    {plan.description ? <div className="border-bottom py-10">{plan.description}</div> : ""}
                    <div className="py-10 border-bottom">
                    <Trans message="Design & Setup Fee" />
                      <div className="price-setup"><FormattedCurrency
                        value={plan.usersetupfees!=0 ? plan.usersetupfees : plan.adminsetupfees}
                        currency='USD'
                      /></div>
                      <div id=":rc:" className=" text-muted pt-10 text-xs">One time fees.</div>
                    </div>
                   
                    
              
              {price ? (
                
                <div className="price-box pt-20 pb-20">
                  <div className="price">
                    <FormattedPrice
                      priceClassName="py-6 text-2xl price"
                      periodClassName="plan"
                      variant="slash"
                      price={price} />                                    
                  </div> 
                  <div id=":rc:" className=" text-muted pt-10 text-xs">Recurring fees.</div>
                </div>
              ) : (
                <div className="text-4xl font-bold">
                  <Trans message="Free" />
                </div>
              )}
                  </div>
                  <div className="pricing-feature">
                    <ProductFeatureList product={plan} />
                    
                  </div>
                  <div className="pricing-footer">     
                    <a onClick={() => {
                        if (isLoggedIn || !price || !plan) return;
                        setInLocalStorage('be.onboarding.selected', {
                          productId: plan.id,
                          priceId: price.id,
                        });
                      }} className="btn btn-act rounded btn-line mt-20 mb-20" href={upgradeRoute}>
                      {plan.free ? (
                        <Trans message="Get started" />
                      ) : (
                        <Trans message="Get started" />
                      )}
                    </a>
                    
                  </div>
                </div>
           
            
            <div>
              
              
              
              
             
            </div>
          </m.div>
        );
      })}
    </Fragment>
  );
}

function SkeletonLoader() {
  return (
    <Fragment>
      <PlanSkeleton key="skeleton-1" />
      <PlanSkeleton key="skeleton-2" />
      <PlanSkeleton key="skeleton-3" />
    </Fragment>
  );
}

function PlanSkeleton() {
  return (
    <m.div
      {...opacityAnimation}
      className="w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350"
    >
      <Skeleton className="my-10" />
      <Skeleton className="mb-40" />
      <Skeleton className="mb-40 h-30" />
      <Skeleton className="mb-40 h-40" />
      <Skeleton className="mb-20" />
      <Skeleton />
      <Skeleton />
    </m.div>
  );
}
